/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import CookieConsent from 'react-cookie-consent'

import Navbar from "./Navbar/NavbarCmp"
import Footer from "./Footer/Footer"
import { withTranslation } from "react-i18next"


const Layout = ({ children, id, t, noFooter }) => {
  return (
    <>
      <CookieConsent
        location="bottom"
        buttonText={t('COOKIES')}
        declineButtonText="Decline"
        cookieName="gatsby-gdpr-google-analytics">
        {t('COOKIES_TEXT')}
      </CookieConsent>
      <div id={id ? id : ''}>
        <Navbar/>
        <main>{children}</main>
        {noFooter===true ? <div/> : <Footer/> }
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  t: PropTypes.any
}

export default withTranslation()(Layout)
