import React, { useState, useEffect } from "react"
import { Container, Row, Col } from "reactstrap"
import logoFooter from "../../assets/logo_white.png"
import membership from "../../assets/apg-logo.jpg"
import footerFlag from "../../assets/flag-sw.svg"
import mailIco from "../../assets/mail-ico.png"
import locationIco from "../../assets/location-icon.png"
import phoneIco from "../../assets/phone-ico.png"
import fb from "../../assets/fb.png"
import instagram from "../../assets/inst.png"
import { withTranslation } from "react-i18next"
import Link  from "../../components/Link/Link"
import terms  from '../../assets/TermsAndConditions.pdf'
import privacy  from '../../assets/PrivacyPolicy.pdf'

import "./Footer.scss"



const FooterCmp = ({ t }) => {
    var year=new Date().getFullYear();
    
    return (
        <footer className="footer">
            <Container className="footer-container">
                <Row>
                    <Col xs="12" md="12" lg="3" className="logo-col">
                        <div className="details">
                            <img src={logoFooter} className="footer-logo" />
                            <p>{t("FOOTER_TITLE")}</p>
                            <p className="hashtag">#beCroatian</p>
                        </div>
                    </Col>
                    <Col xs="12" md="3" lg="2" className="explore">
                        <h4>{t("FOOTER_EXPLORE")}</h4>
                        <ul className="list">
                            <li>
                                <Link to="/">{t("FOOTER_HOME")}</Link>
                            </li>
                            <li>
                                <Link to="/about-us">{t("FOOTER_ABOUT")}</Link>
                            </li>
                            <li>
                                <Link to="/our-services">{t("FOOTER_SERVICES")}</Link>
                            </li>
                            <li>
                                <Link to="/blog">{t("FOOTER_BLOG")}</Link>
                            </li>
                            <li>
                                <Link to="/contact">{t("FOOTER_CONTACT")}</Link>
                            </li>
                        </ul>
                    </Col>
                    <Col xs="12" md="4" lg="3" className="contact-col">
                        <h4>{t("FOOTER_CONTACT")}</h4>
                        <div className="contact-detail">
                            <img src={locationIco} /> <span>{t("FOOTER_ADDRESS")}</span>
                        </div>
                        <div className="contact-detail">
                            <img src={mailIco} /> <span>{t("FOOTER_EMAIL")}</span>
                        </div>
                        <div className="contact-detail">
                            <img src={phoneIco} /> <span>{t("FOOTER_PHONE")}</span>
                        </div>
                        <div className="social-media">
                            <a href="https://www.facebook.com/salonaconsulting" target='_blank'>
                                <div className="social-element fb">
                                    <img src={fb} />
                                </div>
                            </a>
                            <a href="https://www.instagram.com/croatiancitizenship" target='_blank'>
                                <div className="social-element instagram">
                                    <img src={instagram} />
                                </div>
                            </a>
                        </div>
                    </Col>
                    <Col xs="12" md="4" lg="3" className="membership">
                        <div className='first'>
                            <h4>{t("FOOTER_MEMBERSHIP")}</h4>
                            <img src={membership} className="footer-logo" />
                        </div>
                        <div className='second'>
                            <h4>{t("FOOTER_REGISTERED")}</h4>
                            <img src={footerFlag} className="footer-logo" />
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className="copy-bar">
                <span>
                    {t("FOOTER_COPY")} © {year}{t("FOOTER_YEAR")} | <a href={terms} target='_blank'>{t("FOOTER_TERMS")}</a> |{" "}
                    <a href={privacy} target='_blank'>{t("FOOTER_PRIVACY")}</a> 
                    <p className='altalogy'>
                        <span>
                            Made with <i className="fa fa-heart pulse"></i> by <a href="https://altalogy.com/" target="_blank">Altalogy</a>
                        </span> 
                    </p>
                </span>
            </div>
        </footer>
    )
}

export default withTranslation()(FooterCmp)
