import React, { useState, useEffect } from "react"
import {
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Container,
} from "reactstrap"
import logo from "../../assets/logo.png"
import ico from "../../assets/change-lang-ico.png"
import "./Navbar.scss"
import DropdownComponent from "../UI/Dropdown/Dropdown"
import { withTranslation } from "react-i18next"
import { navigate } from "gatsby"
import Link from "../Link/Link"

const NavbarCmp = ({ t }) => {
  const [collapsed, setCollapsed] = useState(true)
  const [fixed, setFixed] = useState(false)
  const toggleNavbar = () => setCollapsed(!collapsed)

  const listenerScroll = () => {
    if (window.pageYOffset > 101) {
      setFixed(true)
    } else {
      setFixed(false)
    }
  }
  useEffect(() => {
    if (!collapsed) {
      document.body.style.overflow = "hidden"
      document.documentElement.style.overflow = "hidden"
      window.removeEventListener("scroll", listenerScroll)
    } else {
      document.body.style.overflow = "unset"
      document.documentElement.style.overflow = "unset"
      window.addEventListener("scroll", listenerScroll)
    }
  }, [collapsed])

  const langSelect = l => {
    const rgxBlog = /^(\/((|blog)\/(en|es|pt)\/))/
    const rgxBlog2 = /^(\/((|blog)\/(en|es|pt)))/
    const rgx = /^(\/((en|es|pt)))/
    const rgxBlogSplit = /^(\/((|blog)\/(en|es|pt)\/))/
    const windowGlobal = typeof window !== 'undefined' && window
    const pathUrl = windowGlobal && windowGlobal.location && windowGlobal.location.pathname 
      ? windowGlobal.location.pathname 
      : ''
    if (rgxBlog.test(pathUrl) || rgxBlog2.test(pathUrl)) {
      const locationBlog = pathUrl.split(rgxBlogSplit)
      if ( locationBlog && locationBlog.length > 0 ) {
        navigate('/blog/' + l)
      } else {
        navigate('/blog/' + l)
      }
     
    } else {
      const location = pathUrl.split(rgx)
      if ( location && location.length > 0 ) {
        navigate('/' + l + location[location.length - 1])
      } else {
        navigate('/' + l + pathUrl)
      }
    }
  }

  return (
    <Navbar
      className={fixed ? "nav-bg" : "nav-bg nav-bg-none"}
      color="faded"
      light
    >
      <NavbarBrand className="mr-auto">
        <Link to="/">
          <img src={logo} className="nav-logo" alt=""/>
        </Link>
      </NavbarBrand>
      <DropdownComponent
        ico={ico}
        title={t("CHANGE_LANGUAGE")}
        onSelect={langSelect}
        items={["en", "es", "pt"]}
      />
      <NavbarToggler onClick={toggleNavbar} className="mr-2">
        <div className="togler-menu">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <span className="txt-menu">{t("NAV_MENU")}</span>
      </NavbarToggler>
      {!collapsed && (
        <div className="fullscreen-nav">
          <Navbar>
            <NavbarBrand className="mr-auto">
              <Link to="/">
                <img src={logo} className="nav-logo" alt=""/>
              </Link>
            </NavbarBrand>
            <NavbarToggler onClick={toggleNavbar} className="mr-2">
              <span>x</span>
            </NavbarToggler>
          </Navbar>
          <Container>
            <Nav>
              <NavItem>
                <Link to="/">{t("NAV_HOME")}</Link>
              </NavItem>
              <NavItem>
                <Link to="/about-us">{t("NAV_ABOUT")} </Link>
              </NavItem>
              <NavItem>
                <Link to="/our-services">{t("NAV_SERVICES")}</Link>
              </NavItem>
              <NavItem>
                <Link to="/blog">{t("NAV_BLOG")}</Link>
              </NavItem>
              <NavItem>
                <Link to="/contact">{t("NAV_CONTACT")}</Link>
              </NavItem>
            </Nav>
          </Container>
        </div>
      )}
    </Navbar>
  )
}

export default withTranslation()(NavbarCmp)
