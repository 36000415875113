import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { 
  Dropdown, 
  DropdownToggle,
  DropdownMenu,
  DropdownItem
  } from 'reactstrap'
import './Dropdown.scss'
import { withTranslation } from  'react-i18next'

const DropdownComponent = ({
  title,
  items,
  onSelect,
  t,
  ico
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(prevState => !prevState);

  const getItems = () => {
    const item = items.map((item, idx) => {
      if(idx < items.length - 1) {
        return (
          <>
            <DropdownItem onClick={()=>onSelect(item)} key={idx.toString()}>{t(item)}</DropdownItem>
            <DropdownItem divider />
          </>
        )
      } else {
        return (
          <>
            <DropdownItem  onClick={()=>onSelect(item)} key={idx.toString()}>{t(item)}</DropdownItem>
          </>
        )
      }
    })
    return item
  }

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle caret>
        {ico && <img src={ico} className='dropdown-ico' alt=""/>}
        <span>{title}</span>
      </DropdownToggle>
      <DropdownMenu>
        {
          items && items.length > 0 && getItems()
        }
      </DropdownMenu>
    </Dropdown>
  )
}

DropdownComponent.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array
}

export default withTranslation()(DropdownComponent)