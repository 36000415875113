import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const LinkComponent = ({ to, children }) => {
  const rgx = /^(\/((en|es|pt)))/
  // const rgxBlog = /^(\/((en|es|pt)\/(|blog)\/(en|es|pt)\/))/s
  const windowGlobal = typeof window !== 'undefined' && window
  let newLocation = rgx.exec(windowGlobal && windowGlobal.location && windowGlobal.location.pathname 
    ? windowGlobal.location.pathname : '')
  let hrefLink = to
  if (newLocation && newLocation.length > 0) {
    if ( to === '/blog' ) {
      hrefLink = to + newLocation[0]
    } else {
      hrefLink = newLocation[0] + to
    }
  } else {
    if ( to === '/blog' ) {
      hrefLink = to + '/en'
    } else {
      hrefLink = '/en' + to 
    }
  }
  return (
    <Link to={hrefLink}>{children}</Link>
  )
}

LinkComponent.propTypes = {
  to: PropTypes.string,
  children: PropTypes.any
}

export default LinkComponent
